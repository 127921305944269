<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-08-31 11:12:21
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-08 10:26:54
 * @FilePath: \official_website\src\views\products\details\Dna.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- DNA倍体癌症早筛解决方案  -->
<template>
  <div id="dna">
    <Header></Header>
    <div class="dna-conter">
      <div class="dna-img">
        <Breadcrumb :productType="productType" :productName="productName"></Breadcrumb>
        <!-- <img src="@/assets/image/product-detail/programme-3.png" alt="" /> -->
        <div class="illustrate-box">
          <div class="title wow animate__animated animate__fadeInUp">DNA倍体癌症早筛解决方案</div>
          <div class="line"></div>
          <ul class="keywords wow animate__animated animate__fadeInUp">
            <li>快速</li>
            <li>简洁</li>
            <li>精准</li>
            <li>高效</li>
          </ul>
        </div>
      </div>
      <div class="dna-description">
        <p class="description-text wow animate__animated animate__fadeInUp">
          在细胞癌变过程中，细胞形态的改变源自于细胞核DNA含量的变化。通过检测细胞核DNA的含量，能够在细胞学检测的基础上，更早的预测癌症风险。
        </p>
        <ul class="dna-icons">
          <li class="wow animate__animated animate__fadeInUp">
            <img src="@/assets/image/product-detail/icon-1.png" alt="" />
            <div class="text">自动化制片染色</div>
          </li>
          <li class="wow animate__animated animate__fadeInUp">
            <img src="@/assets/image/product-detail/icon-2.png" alt="" />
            <div class="text">数字切片扫描</div>
          </li>
          <li class="wow animate__animated animate__fadeInUp">
            <img src="@/assets/image/product-detail/icon-3.png" alt="" />
            <div class="text">人工智能辅助诊断</div>
          </li>
          <li class="wow animate__animated animate__fadeInUp">
            <img src="@/assets/image/product-detail/icon-4.png" alt="" />
            <div class="text">报告签发</div>
          </li>
        </ul>
      </div>

      <RelatedProduct :swiperData="relatProd"></RelatedProduct>
     
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "@/views/componets/Header.vue";
import Footer from "@/views/componets/Footer.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
import Breadcrumb from "@/views/componets/Breadcrumb.vue";
import api from "../../../api/api";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    RelatedProduct,
    Breadcrumb
  },
  data() {
    return {
      productType: "解决方案",
      productName: "DNA倍体癌症早筛解决方案",
      relatProd: [
        {
          title: "全自动特殊染色机",
          src: require("@/assets/image/product/film-4.png"),
           path: "/product/special"
        },
        {
          title: "DNA定量分析诊断试剂",
          src: require("@/assets/image/product/reagent-2.png"),
          path: "/product/dnaReagent",
        },
        {
          title: "DNA倍体定量分析系统",
          src: require("@/assets/image/product/diagnosis-1.png"),
          path: "/product/dnaProd",
        },
        {
          title: "扫描通量600片",
          src: require("@/assets/image/product/digit-1.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量120片",
          src: require("@/assets/image/product/digit-2.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量30片",
          src: require("@/assets/image/product/digit-3.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量2片",
          src: require("@/assets/image/product/digit-4.png"),
          path: "/product/gemini",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
   async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id)
  },
  mounted() {
     this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
      $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;

        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = []
              tepmArr.forEach((items) => {
                let secData =  this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if(secData.length != 0) {
                  relatedList.push(secData[0])
                }
              })

              this.relatProd = relatedList
           
            } else {
             
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#dna {
  width: 100%;
  height: 100%;
  .dna-conter {
    padding-top: 5rem;
    .dna-img {
      position: relative;
      height: 50rem;
      background-image: url("../../../assets/image/product-detail/programme-3.png");
      background-size: cover;
      // img {
      //   width: 100%;
      // }
      .illustrate-box {
        position: absolute;
        left: 18%;
        top: 30%;
        width: 21.875rem;
        .title {
          font-size: 1.8rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 2.8125rem;
        }
        .line {
          width: 4.25rem;
          height: 3px;
          margin-bottom: 1.25rem;
          background: #177a73;
        }
        .keywords {
          display: flex;
          li {
            width: 22%;
            height: 2.1875rem;
            line-height: 2.1875rem;
            margin-right: 4%;
            color: #fff;
            background: #177a73;
            border-radius: 2.1875rem;
            text-align: center;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
    .dna-description {
      width: 100%;
      height: 37.5rem;
      box-sizing: border-box;
      background-image: url("../../../assets/image/product-detail/des-bg.png");
      background-size: cover;
      padding: 3.75rem 25%;
      .description-text {
        font-size: 1.5rem;
        line-height: 3rem;
        margin-bottom: 5rem;
        font-family: "OPlusSans3-Regular";
      }
      .dna-icons {
        display: flex;
        li {
          width: 14%;
          margin-right: 14.66%;
          &:nth-of-type(4n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            margin-bottom: 1.25rem;
          }
          .text {
            text-align: center;
            font-family: "SourceHanSansSC-Medium";
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>